import { useEffect, useState } from 'react';
import {
    applyOverrides,
    CONFIG,
    CONFIG_URL,
    fetchJson,
    ProviderProps,
    USER_PORTAL_CONFIG_URL,
    userPortalTranslator,
} from 'src/components/AppConfig/AppConfig.utils';

/**
 * Renders the children after loading the config into the session.
 * For Local development, it will use the local config.development.json file
 * If the config cannot be found show warning in console and allow consumers to manage error handling with isConfigSet()
 * @param children to render once the config has been loaded
 * @param useUserPortalConfigJson boolean to tell the portal to  use 'userportal.config.json' instead of 'config.json' compatibility fix for public
 * @param onConfigLoaded callback to be called once the config has been loaded
 * @returns children once the config has been loaded
 */
export const ProvideConfig = ({
    children,
    useUserPortalConfigJson,
    onConfigLoaded,
}: ProviderProps) => {
    const [loaded, setLoaded] = useState(false);

    const getConfigData = async () => {
        const configUrl = useUserPortalConfigJson ? USER_PORTAL_CONFIG_URL : CONFIG_URL;
        const config = await fetchJson(configUrl);
        if (!config) {
            return null;
        }
        // only translate if we're in the old infrastructure. ie public
        if (useUserPortalConfigJson) {
            return userPortalTranslator(config);
        }
        // only use overrides if in deployed development slot
        if (config?.environment === 'development') {
            return applyOverrides(config);
        }
        return config;
    };

    const loadConfig = async () => {
        const config = await getConfigData();
        if (config) {
            sessionStorage.setItem(CONFIG, JSON.stringify(config));
        } else {
            // eslint-disable-next-line no-console
            console.warn('Site configuration not present');
        }
        onConfigLoaded();
    };

    useEffect(() => {
        loadConfig()
            .then(() => setLoaded(true))
            .catch(() => setLoaded(false));
    }, []);

    return loaded ? children : null;
};
